import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import axios from 'axios';

axios.defaults.baseURL = 'http://localhost:3030';
// if (process.env.NODE_ENV === 'production') {
//   axios.defaults.baseURL = 'https://server-nodejs-dev.herokuapp.com';
// } else {
//     axios.defaults.baseURL = 'http://localhost:3030';
// }

ReactDOM.render(<App />, document.getElementById('root'));
